<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout wrap>
                <v-flex xs12 md12 text-center>
                  <span style="font-size:20px;font-weight:500;">Users Navigation Logs</span>
                </v-flex>
              </v-layout>
              <v-layout pt-2 wrap px-2 justify-center>
                <v-flex>
                  <v-card>
                    <v-layout pt-2 wrap justify-center>
                      <v-flex text-center>
                        <span style="font-size:16px;font-weight:600">Filter</span>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-center>
                      <v-flex xs6 sm6 md3>
                        <v-menu
                          ref="fromlogpicker"
                          v-model="fromlogpicker"
                          :close-on-content-click="false"
                          :return-value.sync="logfrom"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="logfrom"
                              clearable
                              @click:clear="logfrom = null"
                              label="From"
                              prepend-icon="mdi-calendar"
                              color="#26af82"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="#26af82"
                            :max="logto"
                            v-model="logfrom"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="#26af82" @click="fromlogpicker=false">Cancel</v-btn>
                            <v-btn
                              color="#26af82"
                              text
                              @click="$refs.fromlogpicker.save(logfrom)"
                            >OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs6 sm6 md3>
                        <v-menu
                          ref="tologpicker"
                          v-model="tologpicker"
                          :close-on-content-click="false"
                          :return-value.sync="logto"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="logto"
                              label="To"
                              clearable
                              @click:clear="logto = null"
                              prepend-icon="mdi-calendar"
                              color="#26af82"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker :min="logfrom" v-model="logto" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="#26af82" @click="tologpicker = false">Cancel</v-btn>
                            <v-btn text color="#26af82" @click="$refs.tologpicker.save(logto)">OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs6 sm6 md3 text-center>
                        <v-text-field
                          v-model="loguser"
                          label="Username"
                          clearable
                          @click:clear="loguser = null"
                          color="#26af82"
                          prepend-icon="mdi-account"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap px-2 justify-center>
                <v-flex xs12 md12>
                  <v-card-title>
                    <v-layout wrap justify-center align="center">
                      <v-flex xs12 sm6 md3 pt-3>
                        <span style="font-size:14px;font-weight:600">Download Data:</span>
                        <!-- <v-btn text color="#26af82">
                          <download-csv :data="logs" name="Website Logs.csv">CSV</download-csv>
                        </v-btn>-->

                        <a
                          :href="baseURL+'/log/pdfnavigationreport?from='+logfrom+'&to='+logto+'&username='+loguser"
                          download
                        >
                          <v-btn text color="red">PDF</v-btn>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-card-title>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12 pt-2>
                      <v-card></v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      userName: null,
      msg: null,
      headers: [
        {
          text: "OS",
          align: "left",
          sortable: true,
          value: "os",
          width: "120px"
        },
        { text: "Log Time", value: "logtime", width: "170px" },
        { text: "Browser", value: "browser", width: "170px" },
        // { text: "Type", value: "type", width: "160px" },
        { text: "IP", value: "ipv4", width: "150px" },
        { text: "Page", value: "pagename", width: "100px" },
        { text: "Username", value: "username", width: "150px" },
        { text: "Action", value: "_id", align: "center", width: "150px" }
      ],
      logs: [],
      itemsPerPage: 10,
      page: 1,
      totalpages: 0,
      ItemsCount: [5, 10, 25, 50],
      logfrom: null,
      logto: null,
      loguser: null,
      fromlogpicker: false,
      tologpicker: false,
      message: ""
    };
  },
  beforeMount() {
    var startdate;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    startdate = yyyy + "-" + mm + "-" + "01";
    this.logfrom = startdate;
    this.logto = today;
    //   this.getLogs();
    // },
    // watch: {
    //   logfrom: function() {
    //     this.page = 1;
    //     this.getLogs();
    //   },
    //   logto: function() {
    //     this.page = 1;
    //     this.getLogs();
    //   },
    //   loguser: function() {
    //     this.page = 1;
    //     this.getLogs();
    //   },
    //   itemsPerPage: function() {
    //     this.page = 1;
    //     this.getLogs();
    //   },
    //   page: function() {
    //     if (this.page > 0) this.getLogs();
    //   }
  },
  methods: {
    prevpage() {
      if (this.page > 1) this.page--;
    },
    nextpage() {
      if (this.page < this.totalpages) this.page++;
    },
    getLogs() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/log/navigationreport",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: {
          count: this.itemsPerPage,
          page: this.page,
          from: this.logfrom,
          to: this.logto,
          username: this.loguser
        }
      })
        .then(response => {
          if (response.data.status == false) {
            this.appLoading = false;
            this.logs = [];
            this.message = "No data found";
          } else {
            this.logs = response.data.data;
            this.totalpages = response.data.pages;
            for (var i = 0; i < this.logs.length; i++) {
              var dt = new Date(this.logs[i].logtime);
              dt = dt.toString();
              // dt = dt.slice(4, 25);
              this.logs[i].logtime = dt;

              if (this.logs[i].userid) {
                this.logs[i].username = this.logs[i].userid.username;
              } else this.logs[i].username = "Guest";
            }
            this.appLoading = false;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Something Went Wrong";
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
</style>